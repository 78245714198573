
// Vue
import {Options, Vue} from "vue-class-component";

// Models
import {AppBankIndex} from "@/models/app/bank";

// Components
import {
  ElDialog,
  ElButton,
} from "element-plus";
import {Search} from "@element-plus/icons-vue";

// Services
import {getInformation} from "@/services/app/data";
import {getTranslation} from "@/services/app/translation";

@Options({
  props: [
    "bank",
  ],
  components: {
    ElDialog,
    ElButton,
    Search,
  },
})
export default class AppAdministratorMasterDataBanksListFormLookupIndexVue extends Vue {
  isDialogVisible = false;

  bank: AppBankIndex = new AppBankIndex();

  get translation(): any {
    return getTranslation([
      "bank",
      "leadingNumber",
      "swift",
      "postCode",
      "review",
    ]);
  }

  getInformation(data: string): string {
    return getInformation(data);
  }
}
