import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bed6fb1"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "app-administrator-master-data-banks-list-form-lookup" }
const _hoisted_2 = { class: "app-administrator-master-data-banks-list-form-lookup__action" }
const _hoisted_3 = { class: "bank" }
const _hoisted_4 = { class: "bank__data" }
const _hoisted_5 = { class: "bank__data-information" }
const _hoisted_6 = { class: "bank__data-information__item" }
const _hoisted_7 = { class: "bank__data-information__item-label" }
const _hoisted_8 = { class: "bank__data-information__item-value" }
const _hoisted_9 = { class: "bank__data-information__item" }
const _hoisted_10 = { class: "bank__data-information__item-label" }
const _hoisted_11 = { class: "bank__data-information__item-value" }
const _hoisted_12 = { class: "bank__data-information__item" }
const _hoisted_13 = { class: "bank__data-information__item-label" }
const _hoisted_14 = { class: "bank__data-information__item-value" }
const _hoisted_15 = { class: "bank__data-information__item" }
const _hoisted_16 = { class: "bank__data-information__item-label" }
const _hoisted_17 = { class: "bank__data-information__item-value" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Search = _resolveComponent("Search")!
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("a", {
        href: "#",
        onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.isDialogVisible = true), ["prevent"]))
      }, [
        _createVNode(_component_Search)
      ])
    ]),
    _createVNode(_component_el_dialog, {
      title: _ctx.translation.review,
      modelValue: _ctx.isDialogVisible,
      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.isDialogVisible) = $event))
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.translation.bank), 1),
                _createElementVNode("span", _hoisted_8, _toDisplayString(_ctx.getInformation(_ctx.bank.content.title)), 1)
              ]),
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("span", _hoisted_10, _toDisplayString(_ctx.translation.postCode), 1),
                _createElementVNode("span", _hoisted_11, _toDisplayString(_ctx.getInformation(_ctx.bank.content.postCode)), 1)
              ]),
              _createElementVNode("div", _hoisted_12, [
                _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.translation.leadingNumber), 1),
                _createElementVNode("span", _hoisted_14, _toDisplayString(_ctx.getInformation(_ctx.bank.content.leadingNumber)), 1)
              ]),
              _createElementVNode("div", _hoisted_15, [
                _createElementVNode("span", _hoisted_16, _toDisplayString(_ctx.translation.swift), 1),
                _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.getInformation(_ctx.bank.content.swift)), 1)
              ])
            ])
          ])
        ])
      ]),
      _: 1
    }, 8, ["title", "modelValue"])
  ]))
}