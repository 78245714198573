// Store
import store from "@/store";

export interface AppBankCreateIndex {
  organization_id: string | string[];
  content: {
    title: string;
    postCode: string;
    leadingNumber: string;
    swift: string;
  };
}

export class AppBankCreateIndex implements AppBankCreateIndex {
  organization_id: string | string[];
  content: {
    title: string;
    postCode: string;
    leadingNumber: string;
    swift: string;
  };

  constructor() {
    this.organization_id = store.getters.getOrganizationMember.organization_id ?? "";
    this.content = {
      title: "",
      postCode: "",
      leadingNumber: "",
      swift: "",
    };
  }
}